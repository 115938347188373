/* eslint-disable @typescript-eslint/no-empty-function */
import { AuthRole } from '@newfront-insurance/auth-api';
import { useHasAnyScope, type AuthProviderContext } from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useEffect, useMemo, useState, useRef } from 'react';

import { DevToolsModal } from '../client/components/dev-tools-modal';

export type FeatureFlagControlPanelContextType = {
  /**
   * Keeps track of flags that are currently rendered in the app
   */
  flagEvaluations: Set<string>;
  addFlagEvaluation: (flagKey: string) => void;
  removeFlagEvaluation: (flagKey: string) => void;
  appScope: AppScopeEnum;
};

export enum AppScopeEnum {
  ADMIN_NAVIGATOR = 'ADMIN_NAVIGATOR',
  CLIENT_NAVIGATOR = 'CLIENT_NAVIGATOR',
}

export const FeatureFlagControlPanelContext = createContext<FeatureFlagControlPanelContextType>({
  flagEvaluations: new Set<string>(),
  addFlagEvaluation: () => {},
  removeFlagEvaluation: () => {},
  appScope: AppScopeEnum.ADMIN_NAVIGATOR,
});

export function FeatureFlagControlPanelProvider({
  children,
  appScope,
  authProvider,
}: PropsWithChildren & {
  appScope: AppScopeEnum;
  authProvider: Provider<AuthProviderContext>;
}): JSX.Element {
  /**
   * Use a ref instead of state to track flags.
   * This prevents re-renders when the currently rendered flags are updated.
   */
  const flagsRef = useRef<Set<string>>(new Set());
  const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);

  const addFlagEvaluation = useCallback((flagKey: string) => {
    flagsRef.current.add(flagKey);
  }, []);

  const removeFlagEvaluation = useCallback((flagKey: string) => {
    flagsRef.current.delete(flagKey);
  }, []);

  const canViewDevTools = useHasAnyScope(authProvider, [AuthRole.ENGINEER]);

  // Add keyboard shortcut handler
  useEffect(() => {
    if (canViewDevTools) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.metaKey && event.shiftKey && event.key.toLowerCase() === 'd') {
          setIsDebugModalOpen(!isDebugModalOpen);
          // Prevent default browser behavior
          event.preventDefault();
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, [isDebugModalOpen, canViewDevTools]);

  const value = useMemo(
    () => ({
      flagEvaluations: flagsRef.current,
      addFlagEvaluation,
      removeFlagEvaluation,
      appScope,
    }),
    [addFlagEvaluation, removeFlagEvaluation, appScope],
  );

  return (
    <FeatureFlagControlPanelContext.Provider value={value}>
      {children}
      {canViewDevTools && (
        <DevToolsModal
          isOpen={isDebugModalOpen}
          onOpenChange={setIsDebugModalOpen}
          featureFlagControlPanelContext={value}
        />
      )}
    </FeatureFlagControlPanelContext.Provider>
  );
}
