import { useState } from 'react';

import type { Employee } from './types';
import { EmployeeAvatar } from '../avatar-employee';
import { EmployeeProfile } from '../employee-profile';
import type { EmployeeDetailsPopoverVariant } from '../employees-details-popover/types';
import { IconButton } from '../icon-button';
import { ControlledPopper } from '../popper';

export * from './types';

interface Props {
  employee: Employee;
  isWide?: boolean;
  onCopyEmail?: () => unknown;
  variant?: EmployeeDetailsPopoverVariant;
  hideEmailAddress?: boolean;
}

const REGULAR_SIZE = 32;
const WIDE_SIZE = 48;

export function EmployeeBadge({ employee, isWide, variant, onCopyEmail, hideEmailAddress }: Props): JSX.Element {
  const boxWidth = isWide ? 352 : 328;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleOpen = (): void => setIsPopoverOpen(true);
  const handleClose = (): void => setIsPopoverOpen(false);

  return (
    <div className="flex items-center gap-3">
      <ControlledPopper
        isOpen={isPopoverOpen}
        onToggle={handleClose}
        portalElement={document.body}
        // eslint-disable-next-line react/no-unstable-nested-components
        trigger={({ triggerRef }) => (
          <EmployeeAvatar
            ref={triggerRef}
            employeeType={employee.type}
            initials={`${employee?.firstName?.[0]}${employee?.lastName?.[0]}`}
            sizeInPixels={isWide ? WIDE_SIZE : REGULAR_SIZE}
            imageUrl={employee?.imageUrl}
            onClick={handleOpen}
            isActive={isPopoverOpen}
          />
        )}
      >
        <div className="relative rounded-md  border-[#aaa] bg-white p-4 shadow-lg" style={{ width: boxWidth }}>
          <div className="absolute right-4 top-4">
            <IconButton type="close" onClick={handleClose} />
          </div>

          <div className="flex flex-col">
            <EmployeeProfile
              variant={variant}
              name={employee.name}
              title={employee?.title}
              imageUrl={employee.imageUrl}
              email={employee.email}
              phone={employee?.phone}
              extension={employee?.extension}
              departments={employee.departments}
              onCopyEmail={onCopyEmail}
              team={employee.team}
              hideEmailAddress={hideEmailAddress}
            />
          </div>
        </div>
      </ControlledPopper>
    </div>
  );
}
