import { isNotEmpty } from '@newfront-insurance/core';
import { CircleSpinnerInline, IconButton, MultiObjectSelector, colors, useFuzzy } from '@newfront-insurance/core-ui';
import { LineOfCoverageStageFilter } from '@newfront-insurance/coverage-api';
import type { CarrierQuote } from '@newfront-insurance/quoting-api';
import { useState, useRef, Suspense } from 'react';

import { useCarrierQuotes } from '../../../../quoting/client/queries/use-carrier-quotes';
import { LineOfCoverageCard } from '../../components/line-of-coverage-card';
import { useSuspenseLinesOfCoverage } from '../../queries/use-lines-of-coverage';
import type { EnrichedLoc } from '../../types';

type EnrichedLocWithCarrierQuote = EnrichedLoc & { carrierQuote?: CarrierQuote };

interface Props {
  onChange: (value: string[]) => void;
  accountUuid: string;
  initLineOfCoverageUuids?: string[];
  stage?: LineOfCoverageStageFilter;
  disabled?: boolean;
  isCompact?: boolean;
}

export function LineOfCoverageSelector(props: Props): JSX.Element {
  return (
    <Suspense fallback={<CircleSpinnerInline />}>
      <LineOfCoverageSelectorInner {...props} />
    </Suspense>
  );
}

function LineOfCoverageSelectorInner({
  onChange,
  accountUuid,
  initLineOfCoverageUuids,
  stage = LineOfCoverageStageFilter.BOUNDED,
  disabled = false,
  isCompact = false,
}: Props): JSX.Element {
  const { data: allLineOfCoverageCandidates } = useSuspenseLinesOfCoverage(
    {
      accountUuid: accountUuid!,
      stage,
    },
    {
      // miniminze refetching when no needed
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
  const { data: carrierQuotes } = useCarrierQuotes(
    {
      accountUuid: accountUuid!,
    },
    {
      enabled: stage !== LineOfCoverageStageFilter.BOUNDED,
    },
  );

  // Find loc created for carrier quotes and
  const carrierQuoteLocToCarrierQuoteMap = carrierQuotes
    ? carrierQuotes
        .flatMap((carrierQuote) =>
          carrierQuote.coverageDetails
            .filter((coverageDetail) => !!coverageDetail.uuid)
            .map((coverageDetail) => [coverageDetail.uuid, carrierQuote]),
        )
        .reduce((map, [uuid, carrierQuote]) => map.set(uuid, carrierQuote), new Map())
    : new Map();
  const lineOfCoverageCandidates = allLineOfCoverageCandidates
    ?.filter(
      (loc) =>
        stage === LineOfCoverageStageFilter.BOUNDED ||
        loc.lineOfCoverageStatus !== 'QUOTE' ||
        carrierQuoteLocToCarrierQuoteMap.has(loc.uuid),
    )
    .map((loc) => ({
      ...loc,
      carrierQuote: carrierQuoteLocToCarrierQuoteMap.get(loc.uuid),
    }));

  // Initialize with preselected items if they exist
  const initialSelectedItems = isNotEmpty(initLineOfCoverageUuids)
    ? lineOfCoverageCandidates?.filter((loc) => initLineOfCoverageUuids.includes(loc.uuid)) ?? []
    : [];
  const [selectedLinesOfCoverage, setSelectedLinesOfCoverage] = useState<EnrichedLoc[]>(initialSelectedItems);

  const { results, search } = useFuzzy(lineOfCoverageCandidates ?? [], {
    keys: [
      'title',
      'displayName',
      'policy.policyNumber',
      'policy.carrier.name',
      'carrierQuote.marketAccess.carrier.name',
      'lineOfCoverageStatus',
    ],
  });

  const handleSelectLinesOfCoverage = (locs: EnrichedLoc[]) => {
    setSelectedLinesOfCoverage(locs);
    onChange(locs.map((loc) => loc.uuid));
  };
  const lineOfCoverageSelectorRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={lineOfCoverageSelectorRef}>
      <MultiObjectSelector<EnrichedLocWithCarrierQuote>
        items={results}
        itemToString={(loc) => loc?.displayName ?? ''}
        getItemKey={(loc) => loc?.uuid ?? ''}
        renderItem={(loc, { isHovering, isSelected }) => (
          <LineOfCoverageCard
            lineOfCoverage={loc}
            isHighlighted={isSelected}
            isHovering={isHovering}
            cardProps={{
              hideBorder: true,
            }}
            defaultExpanded={false}
          />
        )}
        renderSelectedItems={(loc, { remove, isRemovable }) => (
          <LineOfCoverageCard
            lineOfCoverage={loc}
            actions={isRemovable ? [<IconButton key="close-button" type="close" onClick={remove} />] : []}
            isExpandable
            defaultExpanded={false}
            cardProps={{
              hideBorder: true,
              backgroundColor: colors.steel[50],
              padding: isCompact ? 'none' : 'default',
            }}
            isCompact={isCompact}
          />
        )}
        renderItemHover={(loc) => (
          <div
            style={{
              width: 460,
            }}
          >
            <LineOfCoverageCard defaultExpanded lineOfCoverage={loc} />
          </div>
        )}
        onSelect={(locs) => handleSelectLinesOfCoverage(locs)}
        selectedItems={selectedLinesOfCoverage}
        onSearch={search}
        disabled={disabled}
        isCompact={isCompact}
        popoverPortalElement={lineOfCoverageSelectorRef.current?.parentElement ?? undefined}
      />
    </div>
  );
}
