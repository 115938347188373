import { createContext, useContext } from 'react';

import type { Application } from './control/registry';
import type { Schema } from '../../types';

export const SchemaInputContext = createContext<{
  schema: Schema | null;
  accountUuid?: string;
  app?: Application;
  disabled?: boolean;
}>({
  schema: null,
});

export function useSchemaAccountUuid(): string | undefined {
  const context = useContext(SchemaInputContext);

  return context.accountUuid;
}

export function useRootSchema(): Schema {
  const context = useContext(SchemaInputContext);

  if (!context?.schema) {
    throw new Error('missing schema in SchemaInputContext');
  }

  return context.schema;
}
