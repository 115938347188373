import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect } from 'react';

import { FeatureFlagControlPanelContext } from '../providers/feature-flag-control-panel';

/**
 * Load the value of a single feature flag, providing a fallback value. The key should match the
 * name of the flag in LaunchDarkly exactly if `useCamelCaseFlagKeys` is set to false, otherwise it
 * should be the camelcase version of the key.
 */
export function useFeatureFlag<T>(key: string, fallbackValue: T, accountUuid?: string): T {
  const client = useLDClient();

  const { addFlagEvaluation, removeFlagEvaluation } = useContext(FeatureFlagControlPanelContext);

  useEffect(() => {
    // On render, add flag key to flagEvaluations
    addFlagEvaluation(key);

    // On unmount, remove flag key from flagEvaluations
    return () => {
      removeFlagEvaluation(key);
    };
  }, [addFlagEvaluation, key, removeFlagEvaluation]);

  if (!client) {
    return false as T;
  }

  const flagValue = client.variation(key, fallbackValue);

  // If flag contains accountUuids, evaluate feature flag by accountUuid
  if (flagValue.accountUuids && Array.isArray(flagValue.accountUuids)) {
    if (!accountUuid) {
      return fallbackValue;
    }
    return flagValue.accountUuids.includes(accountUuid) || flagValue.accountUuids.includes('ALL')
      ? (true as T)
      : fallbackValue;
  }

  // Else simply return the flagValue
  return flagValue;
}
