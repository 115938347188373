import type { IconProps } from './types';

export function IconClose({ className }: IconProps) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" className={className}>
      <path
        // eslint-disable-next-line max-len
        d="M11.7849 1.77117L7.55606 6L11.7849 10.2288C12.0717 10.5156 12.0717 10.9794 11.7849 11.2662L11.2662 11.7849C10.9794 12.0717 10.5156 12.0717 10.2288 11.7849L6 7.55606L1.77117 11.7849C1.48436 12.0717 1.02059 12.0717 0.733791 11.7849L0.215103 11.2662C-0.071701 10.9794 -0.071701 10.5156 0.215103 10.2288L4.44394 6L0.215103 1.77117C-0.071701 1.48436 -0.071701 1.02059 0.215103 0.733791L0.733791 0.215103C1.02059 -0.071701 1.48436 -0.071701 1.77117 0.215103L6 4.44394L10.2288 0.215103C10.5156 -0.071701 10.9794 -0.071701 11.2662 0.215103L11.7849 0.733791C12.0656 1.02059 12.0656 1.48436 11.7849 1.77117Z"
        fill="currentColor"
      />
    </svg>
  );
}
