import type { RouterUseQueryOptions } from '@newfront-insurance/data-layer-client';
import type { RouterQueryInput } from '@newfront-insurance/data-layer-server';

import type { SharedRouter } from '../../../bff';
import { useAdminTRPC } from '../../../shared/providers/trpc';

export function useCarrierQuotes(
  params: RouterQueryInput<SharedRouter, 'carrierQuotes.getCarrierQuotes'>,
  options?: RouterUseQueryOptions<SharedRouter, 'carrierQuotes.getCarrierQuotes'>,
) {
  const { useQuery } = useAdminTRPC();

  return useQuery(['carrierQuotes.getCarrierQuotes', params], options);
}
