/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import { CalendarDate } from '@newfront-insurance/string-formatters';
import classnames from 'classnames';

import { cn } from '../../../v2';

interface Props {
  day: CalendarDate;
  onClick: (date: CalendarDate) => unknown;
  isSelected?: boolean;
  isDisabled?: boolean;
  isCurrentMonth?: boolean;
}

export function DayCell(props: Props): JSX.Element {
  const { day, isSelected, isDisabled, isCurrentMonth, onClick } = props;

  const today = CalendarDate.today();
  const isToday = day.isSameDate(today);

  const classNames = classnames({
    DayCell: true,
    isToday,
    isSelected,
    isDisabled,
    isNotThisMonth: !isCurrentMonth,
  });

  return (
    <td
      className={cn(
        'h-[30px] cursor-pointer rounded-full px-[2px] py-0 hover:bg-muted focus:outline-none focus:ring',
        {
          'cursor-not-allowed': isDisabled,
          'bg-fire-200 text-fire-500': isToday && !isSelected,
          'text-steel-300': !isCurrentMonth && !isSelected,
          'bg-brand-400 text-white': isSelected,
          'text-steel-300 line-through hover:bg-transparent': isDisabled,
        },
        classNames,
      )}
      onClick={() => {
        if (isDisabled) return;
        onClick(day);
      }}
      role="button"
      onKeyDown={(e) => (e.key === ' ' || e.key === 'Enter') && !isDisabled && onClick(day)}
      tabIndex={!isDisabled ? 0 : undefined}
      aria-selected={isSelected}
      aria-disabled={isDisabled}
    >
      <span className="text-inherit relative flex h-[25px] w-full items-center justify-center rounded-sm text-base">
        {day.getDate()}
      </span>
    </td>
  );
}

export function EmptyDayCell(): JSX.Element {
  return <td className="h-[30px] px-[2px] py-0" aria-label="empty-day-cell" />;
}
