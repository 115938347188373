import { useMutation } from '@apollo/client';
import { isNotEmpty } from '@newfront-insurance/core';
import { IconEyeOpen, Switch, useFuzzy } from '@newfront-insurance/core-ui';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  IconButtonAsAnchor,
  Input,
} from '@newfront-insurance/core-ui/v2';
import { graphql } from '@newfront-insurance/graphql-frontend';
import { useNotifications } from '@newfront-insurance/next-notifications';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { debounce } from 'lodash';

import type { FeatureFlagControlPanelContextType, AppScopeEnum } from '../../../providers/feature-flag-control-panel';

export const TOGGLE_FEATURE_FLAG = graphql(`
  mutation ToggleFeatureFlag($input: ToggleFeatureFlagInput!) {
    toggleFeatureFlag(input: $input)
  }
`);

interface DevToolsModalProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  featureFlagControlPanelContext: FeatureFlagControlPanelContextType;
}

export function DevToolsModal(props: DevToolsModalProps): JSX.Element {
  const { isOpen, onOpenChange, featureFlagControlPanelContext } = props;
  const { flagEvaluations, appScope } = featureFlagControlPanelContext;

  const ldClient = useLDClient();

  const allFlagEvaluations = ldClient?.allFlags();

  const flagEvaluationsArray = Array.from(flagEvaluations).map((flagKey) => ({
    flagKey,
    evaluation: allFlagEvaluations?.[flagKey],
  }));

  const notifications = useNotifications();

  const [toggleFeatureFlag, { loading }] = useMutation(TOGGLE_FEATURE_FLAG, {
    onCompleted(_, someOtherData) {
      const { featureFlagId, variationValue } = someOtherData?.variables?.input ?? {};
      const title =
        isNotEmpty(featureFlagId) && isNotEmpty(variationValue)
          ? `${featureFlagId} set to ${variationValue}`
          : 'Feature flag toggled';
      notifications.add({
        title,
        type: 'success',
      });
    },
    onError(error) {
      notifications.add({
        title: error.message,
        type: 'error',
      });
    },
  });

  const {
    results: featureFlagSearchResults,
    search: onFeatureFlagSearchChange,
    query: featureFlagSearchQuery,
  } = useFuzzy(flagEvaluationsArray, {
    keys: ['flagKey'],
  });
  const debouncedFeatureFlagSearch = debounce(onFeatureFlagSearchChange, 500);
  const sortedFilteredFlagEvaluations = featureFlagSearchResults.sort((a, b) => a.flagKey.localeCompare(b.flagKey));

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader className="border-b pb-4">
          <DialogTitle>Newfront Dev Tools</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2 p-2">
          <p className="pb-2 text-base font-bold">Evaluated feature flags</p>
          <Input
            placeholder="Search feature flags"
            onChange={(e) => debouncedFeatureFlagSearch(e.target.value)}
            type="search"
            defaultValue={featureFlagSearchQuery}
          />
          <div className="flex h-[30vh] flex-col gap-2 overflow-y-auto">
            {sortedFilteredFlagEvaluations.map((flagEvaluation) => (
              <div key={flagEvaluation.flagKey} className="flex flex-row justify-between px-3 py-1">
                <div className="flex flex-row items-center gap-1">
                  <span className="text-base">{flagEvaluation.flagKey}</span>
                  <IconButtonAsAnchor
                    href={getFeatureFlagLaunchDarklyURL(flagEvaluation.flagKey, appScope)}
                    target="_blank"
                    rel="noreferrer"
                    className="text-foreground"
                  >
                    <IconEyeOpen color="currentColor" size={16} />
                  </IconButtonAsAnchor>
                </div>
                {typeof flagEvaluation.evaluation === 'boolean' && (
                  <Switch
                    enabled={flagEvaluation.evaluation}
                    locked={loading}
                    onClick={() => {
                      toggleFeatureFlag({
                        variables: {
                          input: {
                            featureFlagId: flagEvaluation.flagKey,
                            variationValue: !flagEvaluation.evaluation,
                            appScope,
                          },
                        },
                      });
                    }}
                  />
                )}
                {typeof flagEvaluation.evaluation !== 'boolean' && <span>Not supported</span>}
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function getFeatureFlagLaunchDarklyURL(flagKey: string, appScope: AppScopeEnum) {
  return `https://app.launchdarkly.com/projects/${appScope === 'ADMIN_NAVIGATOR' ? 'default' : 'client-dash'}/flags/${flagKey}`;
}
