/* eslint-disable react/jsx-props-no-spreading */
import pluralize from 'pluralize';
import { useRef } from 'react';
import * as React from 'react';

import { Flexbox } from '../../flexbox';
import { Text } from '../../text';
import { TextLink } from '../../text-link';
import { DefaultNoResultsDisplay, SelectInput, UnfocusedInput } from '../common';
import { Selector } from '../common/selector';
import type { MultiSelectorProps } from '../common/types';
import { useMultiSelector } from '../hooks';

export function MultiObjectSelector<T extends object>({
  items,
  itemToString = (item) => item?.toString() ?? '',
  getItemKey,
  renderItem,
  renderItemHover,
  onSelect,
  selectedItems,
  renderSelectedItems,
  renderNoResults = DefaultNoResultsDisplay,
  emptyPlaceholder = 'Nothing selected',
  placeholder = 'Filter by keyword',
  isLoading,
  isClearable = true,
  onSearch,
  renderInputToolbar,
  renderFooter,
  getDisabledProps,
  testId,
  disabled,
  isCompact,
  popoverPortalElement,
  popoverWidth,
  disableRenderUnfocusedInputToolbar,
}: MultiSelectorProps<T>): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    getComboboxProps,
    getMenuProps,
    getItemProps,
    getInputProps,
    getToggleButtonProps,
    isOpen,
    highlightedIndex,
    openMenu,
    closeMenu,
    inputValue,
    selectedItemKeys,
    getDropdownProps,
    removeSelectedItem,
    isAllSelected,
    selectAll,
    clear,
  } = useMultiSelector<T>({
    items,
    itemToString,
    onSearch,
    getItemKey,
    inputRef,
    onSelect,
    selectedItems,
    getDisabledProps,
  });

  const selectedItemsOverflowStyles: React.CSSProperties = {
    maxHeight: 300,
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  return (
    <div>
      <Selector<T>
        emptyPlaceholder={emptyPlaceholder}
        getComboboxProps={getComboboxProps}
        getDisabledProps={getDisabledProps}
        getIsSelectedItem={(item) => selectedItemKeys.includes(getItemKey(item))}
        getItemKey={getItemKey}
        getItemProps={getItemProps}
        getMenuProps={getMenuProps}
        getToggleButtonProps={getToggleButtonProps}
        highlightedIndex={highlightedIndex}
        header={
          <SelectInput
            inputRef={inputRef}
            renderInputToolbar={renderInputToolbar}
            hasBorder={false}
            assistiveText={inputValue ? `${items.length} ${pluralize('result', items.length)} found` : null}
            {...getInputProps(
              getDropdownProps({
                placeholder,
                onClick: openMenu,
                ref: inputRef,
              }),
            )}
            testId={testId}
            isCompact={isCompact}
          />
        }
        inputRef={inputRef}
        isLoading={isLoading}
        isOpen={isOpen}
        items={items}
        placeholder={placeholder}
        renderInput={({ triggerRef }) => (
          <div ref={triggerRef}>
            <UnfocusedInput
              getToggleButtonProps={getToggleButtonProps}
              emptyPlaceholder={emptyPlaceholder}
              isLoading={isLoading}
              itemToString={itemToString}
              placeholder={placeholder}
              hasValue={selectedItems.length > 0}
              onClear={clear}
              isClearable={isClearable}
              isOpen={isOpen}
              renderSelectedItem={() => (
                <Text testId={`${testId}-num-selected-items`}>{selectedItems.length} selected</Text>
              )}
              renderUnfocusedInputToolbar={
                disableRenderUnfocusedInputToolbar
                  ? undefined
                  : () => (
                      <TextLink
                        onClick={isAllSelected ? clear : selectAll}
                        underline={false}
                        style={{
                          paddingLeft: 8,
                        }}
                        nowrap
                      >
                        {isAllSelected ? 'Unselect all' : 'Select all'}
                      </TextLink>
                    )
              }
              testId={testId}
              disabled={disabled}
              isCompact={isCompact}
            />
          </div>
        )}
        renderInputToolbar={renderInputToolbar}
        renderItem={renderItem}
        renderItemHover={renderItemHover}
        renderNoResults={renderNoResults}
        menuFooter={renderFooter?.(selectedItems, { closeMenu })}
        testId={testId}
        popoverPortalElement={popoverPortalElement}
        popoverWidth={popoverWidth}
      />
      {selectedItems.length > 0 && renderSelectedItems && (
        <div style={selectedItemsOverflowStyles}>
          <Flexbox gap={8} marginTop={8} flexDirection="column" testId="selected-items-container">
            {selectedItems.map((selectedItem, index) =>
              renderSelectedItems(selectedItem, {
                remove: () => removeSelectedItem(selectedItem),
                isRemovable:
                  !disabled && (!getDisabledProps || getDisabledProps?.(selectedItem, { index })?.isRemovable),
              }),
            )}
          </Flexbox>
        </div>
      )}
    </div>
  );
}
