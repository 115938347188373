import type { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from '../../lib/utils';

interface IconButtonBaseProps extends React.PropsWithChildren {
  isActive?: boolean;
  disabled?: boolean;
  className?: string;
}

interface IconButtonAsAnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement>, IconButtonBaseProps {}

interface IconButtonAsButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, IconButtonBaseProps {}

function getClassName(props: IconButtonBaseProps) {
  const { isActive, disabled, className } = props;
  return cn(
    'relative flex items-center justify-center rounded-full border-0 bg-none p-0',
    'cursor-pointer text-steel-400 transition-colors duration-300 ease-in-out',
    'focus:outline-none focus-visible:outline-1 focus-visible:outline-brand-400',
    'h-6 w-6 hover:bg-steel-100 hover:disabled:bg-none dark:hover:bg-steel-500',
    {
      'isActive bg-steel-100 dark:bg-steel-500': isActive,
      isDisabled: disabled,
      'pointer-events-none cursor-not-allowed bg-none opacity-30': disabled,
    },
    className,
  );
}

export const IconButtonAsAnchor = forwardRef<HTMLAnchorElement, IconButtonAsAnchorProps>((props, ref) => {
  const { children, isActive, disabled, className, ...rest } = props;
  return (
    <a ref={ref} {...rest} className={getClassName({ isActive, disabled, className })}>
      {children}
    </a>
  );
});

IconButtonAsAnchor.displayName = 'IconButtonAsAnchor';

export const IconButtonAsButton = forwardRef<HTMLButtonElement, IconButtonAsButtonProps>((props, ref) => {
  const { children, isActive, disabled, className, ...rest } = props;
  return (
    <button
      ref={ref}
      {...rest}
      disabled={disabled}
      type="button"
      className={getClassName({ isActive, disabled, className })}
    >
      {children}
    </button>
  );
});

IconButtonAsButton.displayName = 'IconButtonAsButton';
