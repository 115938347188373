import kebabCase from 'lodash/kebabCase';
import * as React from 'react';

import { generateTestId } from '../../lib/test-helpers';
import { cn } from '../../v2';

interface SwitchProps {
  enabled: boolean;
  locked?: boolean;
  onClick: (enabled: boolean) => unknown;
  testId?: string;
}

function IconMiniCheck(): JSX.Element {
  return (
    <svg width="8" height="6" viewBox="0 0 8 6" style={{ display: 'block' }}>
      <path
        d="M2.71 5.83314L0.116844 3.23998C-0.0389481 3.08419 -0.0389481 2.83159 0.116844 2.67578L0.681028
        2.11158C0.83682 1.95577 1.08944 1.95577 1.24523 2.11158L2.9921 3.85844L6.73372 0.116844C6.88951 -0.0389481
        7.14213 -0.0389481 7.29792 0.116844L7.8621 0.681044C8.0179 0.836836 8.0179 1.08944 7.8621 1.24524L3.2742
        5.83316C3.1184 5.98895 2.8658 5.98895 2.71 5.83314V5.83314Z"
        className="fill-brand-400"
      />
    </svg>
  );
}

function IconMiniCross(): JSX.Element {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" style={{ display: 'block' }}>
      <path
        d="M7.47094 1.46269L4.93364 3.99999L7.47094 6.53729C7.64302 6.70938 7.64302 6.98764 7.47094 7.15972L7.15973
        7.47093C6.98764 7.64301 6.70938 7.64301 6.5373 7.47093L4 4.93363L1.4627 7.47093C1.29062 7.64301 1.01236 7.64301
        0.840276 7.47093L0.529063 7.15972C0.356981 6.98764 0.356981 6.70938 0.529063 6.53729L3.06636 3.99999L0.529063
        1.46269C0.356981 1.29061 0.356981 1.01235 0.529063 0.840269L0.840276 0.529056C1.01236 0.356973 1.29062 0.356973
        1.4627 0.529056L4 3.06636L6.5373 0.529056C6.70938 0.356973 6.98764 0.356973 7.15973 0.529056L7.47094
        0.840269C7.63936 1.01235 7.63936 1.29061 7.47094 1.46269Z"
        className="fill-steel-200"
      />
    </svg>
  );
}

export function Switch(props: SwitchProps): JSX.Element {
  const { enabled, locked, onClick, testId } = props;

  function onSpace(e: React.KeyboardEvent): void {
    if (e.key === ' ') {
      e.preventDefault();
      onClick(!enabled);
    }
  }

  return (
    <button
      type="button"
      role="checkbox"
      data-testid={testId}
      aria-checked={enabled}
      tabIndex={0}
      className={cn(
        'relative h-5 w-[38px] cursor-pointer rounded-full bg-steel-200 transition-colors duration-100 ease-linear',
        'focus:border-brand-400 focus:outline-none focus:ring-2 focus:ring-brand-400/50',
        {
          switch: true,
          'isEnabled bg-brand-400': enabled,
          'isLocked cursor-not-allowed opacity-40': locked,
        },
      )}
      onKeyDown={(e) => {
        if (!locked) {
          onSpace(e);
        }
      }}
      onClick={(e) => {
        if (!locked) {
          e.stopPropagation();
          onClick(!enabled);
        }
      }}
    >
      <div
        className={cn(
          'h-[14px] w-[14px] rounded-full bg-white shadow-[0px_0px_4px_rgba(0,0,0,0.25)]',
          'absolute left-[3px] top-[3px] rounded-full transition-transform duration-100 ease-in-out',
          {
            'translate-x-[17px]': enabled,
          },
        )}
      >
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          {enabled ? <IconMiniCheck /> : <IconMiniCross />}
        </div>
      </div>
    </button>
  );
}

/**
 * @param labelClassName - Additional class names for the text (default: 'text-sm')
 */
export type SwitchWithLabelProps = SwitchProps & {
  labelClassName?: string;
  label: string;
  testId?: string;
  labelPosition?: 'left' | 'right';
};

export function SwitchWithLabel(props: SwitchWithLabelProps): JSX.Element {
  const { label, onClick, enabled, locked, testId: propTestId, labelClassName, labelPosition = 'left' } = props;
  const testId = propTestId ?? generateTestId(kebabCase(label), 'switch');

  return (
    <div
      className={cn('flex flex-row items-center gap-2', {
        'flex-row-reverse': labelPosition === 'right',
      })}
    >
      <div className="flex items-center">
        <span className={cn('text-sm', labelClassName)}>{label}</span>
      </div>
      <Switch onClick={onClick} enabled={enabled} locked={locked} testId={testId} />
    </div>
  );
}
